<template>
  <div id="how-it-works" class="position-relative overflow-hidden">
    <div class="container space-2">
      <div class="w-md-85 w-lg-65 text-center mx-md-auto mb-5">
        <span class="d-block small font-weight-bold text-cap mb-2">{{ $t('APIOpsCICDPage.HowItWorksSection.Subtitle') }}</span>
        <h2>{{ $t('APIOpsCICDPage.HowItWorksSection.Title') }}</h2>
        <p>{{ $t('APIOpsCICDPage.HowItWorksSection.Description') }}</p>
      </div>
      <div class="row align-items-center mb-9">
        <div class="col-12 col-md-6 mb-2 mb-md-0">
          <figure class="max-w-8rem mb-3">
            <img class="img-fluid" src="@/assets/svg/illustrations/lego-1.svg">
          </figure>
          <h3 class="mb-3">{{ $t('APIOpsCICDPage.HowItWorksSection.Feature1Title') }}</h3>
          <p>{{ $t('APIOpsCICDPage.HowItWorksSection.Feature1Description') }}</p>
        </div>
        <div class="col-12 col-md-6">
          <div class="w-80 w-md-100 w-lg-75 mx-auto">
            <img class="img-fluid" src="@/assets/svg/illustrations/apiops-cicd/cicd-feature-1.svg" alt="Convert AWS Lambda Functions with OpenAPIHub Toolkit">
          </div>
        </div>
      </div>
      <div class="row align-items-center mb-9">
        <div class="col-12 col-md-6 mb-2 mb-md-0 order-md-2">
          <figure class="max-w-8rem mb-3">
            <img class="img-fluid" src="@/assets/svg/illustrations/lego-2.svg">
          </figure>
          <h3 class="mb-3">{{ $t('APIOpsCICDPage.HowItWorksSection.Feature2Title') }}</h3>
          <p>{{ $t('APIOpsCICDPage.HowItWorksSection.Feature2Description') }}</p>
        </div>
        <div class="col-12 col-md-6 order-md-1">
          <div class="w-80 w-md-100 w-lg-75 mx-auto">
            <img class="img-fluid" src="@/assets/svg/illustrations/apiops-cicd/cicd-feature-2.svg" alt="Generate OpenAPIHub Admin API Key">
          </div>
        </div>
      </div>
      <div class="row align-items-center">
        <div class="col-12 col-md-6 mb-2 mb-md-0">
          <figure class="max-w-8rem mb-3">
            <img class="img-fluid" src="@/assets/svg/illustrations/lego-3.svg">
          </figure>
          <h3 class="mb-3">{{ $t('APIOpsCICDPage.HowItWorksSection.Feature3Title') }}</h3>
          <p>{{ $t('APIOpsCICDPage.HowItWorksSection.Feature3Description') }}</p>
        </div>
        <div class="col-12 col-md-6">
          <div class="w-80 w-md-100 w-lg-75 mx-auto">
            <img class="img-fluid" src="@/assets/svg/illustrations/apiops-cicd/cicd-feature-3.svg" alt="Configure your CI/CD pipeline in Git repository">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HowItWorks'
}
</script>

<style lang="scss" scoped>
</style>
